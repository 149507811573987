import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { proprietaMisurataIcons } from "@/utils/proprietaMisurataIcons";
import {
  getProprietaMisurataHelper,
  isDanger,
  isOld,
} from "@/utils/proprietaMisurataUtils";
import { Card, Icon, SvgIcon, Tooltip } from "@mui/material";
import { ComponentProps } from "react";
import typography from "@/theme/base/typography";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  ArcElement,
} from "chart.js";
import Button from "@/elements/Button";
import { ProprietaMisurataDto, ProprietaMisurataValueDto } from "@/api";

ChartJS.register(
  LineController,
  LineElement,
  ArcElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
);

function ProprietaMisurataLabel({
  proprietaMisurata,
  value,
  typographyVariantPrimary = "h3",
  typographyVariantSecondary = "body2",
  alignItems = "center",
}: {
  proprietaMisurata: ProprietaMisurataDto;
  value: ProprietaMisurataValueDto | null;
  typographyVariantPrimary?: ComponentProps<typeof Typography>["variant"];
  typographyVariantSecondary?: ComponentProps<typeof Typography>["variant"];
  alignItems?: "center" | "flex-start";
}) {
  const { size } = typography;

  const type = getProprietaMisurataHelper(proprietaMisurata.type);
  const Icon_ = proprietaMisurataIcons[proprietaMisurata.type];

  if (!type || !Icon_) {
    return null;
  }

  const valueText =
    typeof value?.value === "number" ? type.format(value.value) : "-";
  const label = type.shortLabel || type.label;
  const perc =
    typeof value?.value === "number" ? type.getPercent(value.value) : undefined;

  const danger = isDanger(value);
  const oldValue = isOld(value);

  const color: ComponentProps<typeof Typography>["color"] = danger
    ? "error"
    : "success";

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
      flex={1}
    >
      <Box display="flex" alignItems="left" mb={0.5} flexDirection="column">
      <Box display="flex" alignItems="center" mb={0.5}>
        <Box
          width="1.25rem"
          height="1.25rem"
          color="grey-400"
          fontSize={size.lg}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={1}
          variant="contained"
        >
          <SvgIcon>{Icon_()}</SvgIcon>
        </Box>
        <Typography
          variant={typographyVariantSecondary}
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {label}
        </Typography>
      </Box>

        <Tooltip
          title={
            value
              ? `Ultimo rilevamento: ${moment(value.timestamp).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}`
              : ""
          }
        >
          <Typography
            variant={typographyVariantPrimary}
            fontWeight="bold"
            color={typeof value?.value === "number" ? color : "warning"}
            sx={{ cursor: "pointer" }}
          >
            {valueText}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default ProprietaMisurataLabel;
