import {
  RepartoDto,
  StabilimentoDto,
  useGetStabilimenti,
  UserEsternoDtoAzienda,
} from "@/api";
import {
  Autocomplete,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import { useMemo } from "react";
import { StabilimentoReparti } from "../StabilimentiPicker";

const StabilimentiRepartiPicker = ({
  selectedAzienda,
  stabilimentoReparti,
  selectedStabilimentiReparti,
  onChange,
  onDelete,
}: {
  selectedAzienda: UserEsternoDtoAzienda | undefined;
  stabilimentoReparti: StabilimentoReparti;
  selectedStabilimentiReparti: StabilimentoReparti[];
  onChange: (
    event: React.SyntheticEvent | null,
    stabilimento: StabilimentoDto | null | undefined,
    reparti: RepartoDto[]
  ) => void;
  onDelete: (element: StabilimentoReparti) => void;
}) => {
  const stabilimentiAzienda = useGetStabilimenti({
    aziendaId: selectedAzienda?.id,
  }).data?.data;

  const stabilimentiAvailable = useMemo(() => {
    return (
      stabilimentiAzienda?.filter(
        (stabilimento) =>
          !selectedStabilimentiReparti.find(
            (s) => s.stabilimento?.id === stabilimento.id
          )
      ) ?? []
    );
  }, [stabilimentoReparti, stabilimentiAzienda]);

  const repartiAvailable = useMemo(() => {
    const stabilimento = stabilimentiAzienda?.find(
      (s) => s.id === stabilimentoReparti.stabilimento?.id
    );
    return stabilimento?.reparti ?? [];
  }, [stabilimentoReparti, stabilimentiAzienda]);

  const onStabilimentoChange = (
    event: React.SyntheticEvent | null,
    stabilimento: StabilimentoDto | null
  ) => {
    onChange(event, stabilimento, []);
  };

  const onRepartiChange = (
    event: React.SyntheticEvent | null,
    reparti: RepartoDto[] | null
  ) => {
    onChange(event, stabilimentoReparti.stabilimento, reparti ?? []);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Autocomplete<StabilimentoDto>
            onChange={onStabilimentoChange}
            options={stabilimentiAvailable}
            getOptionLabel={(stabilimento) => stabilimento.nome}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={stabilimentoReparti.stabilimento}
            renderInput={(params) => <TextField {...params} />}
            size="medium"
          />
        </Grid>

        <Grid item xs={5}>
          <Autocomplete<RepartoDto, true> // The second type parameter `true` enables multiple values
            multiple
            isOptionEqualToValue={(option, value) => option.nome === value.nome}
            options={repartiAvailable} // This is your array of RepartoDto options
            getOptionLabel={(reparto) => reparto.nome} // Extract the label for each option
            value={stabilimentoReparti.reparti ?? []} // Array of selected RepartoDto
            onChange={onRepartiChange} // Update state on change
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  stabilimentoReparti.stabilimento != null
                    ? "Tutti i reparti"
                    : ""
                }
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.nome}
                  {...getTagProps({ index })}
                  style={{ margin: "2px" }} // Adjust spacing here
                />
              ))
            }
            size="medium"
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              onDelete(stabilimentoReparti);
            }}
            aria-label="delete"
            style={{ color: "red" }}
          >
            <Icon>delete_outlined</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default StabilimentiRepartiPicker;
