/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */

export type ProprietaMisurataType =
  (typeof ProprietaMisurataType)[keyof typeof ProprietaMisurataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProprietaMisurataType = {
  unknown: "unknown",
  temperatura: "temperatura",
  particelle: "particelle",
  particelle_iso4: "particelle_iso4",
  particelle_iso6: "particelle_iso6",
  particelle_iso14: "particelle_iso14",
  particelle_iso21: "particelle_iso21",
  pressione: "pressione",
  umidita: "umidita",
  potenzaMotore: "potenzaMotore",
  portata: "portata",
  saturazione: "saturazione",
} as const;
