import {
  RepartoDto,
  StabilimentoDto,
  useGetAziende,
  UserEsternoDtoAzienda,
} from "@/api";
import { UserAziendaStabilimentoRepartoDto } from "@/api/models/userAziendaStabilimentoRepartoDto";
import { Button, Grid, InputLabel } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import StabilimentiRepartiPicker from "../StabilimentoRepartiPicker";

export interface StabilimentoReparti {
  stabilimento?: StabilimentoDto | null;
  reparti?: RepartoDto[] | null;
}

const StabilimentiPicker = ({
  selectedAzienda,
  userAziendaStabilimentoRepartoDto,
  onChange,
}: {
  selectedAzienda: UserEsternoDtoAzienda | undefined;
  userAziendaStabilimentoRepartoDto:
    | UserAziendaStabilimentoRepartoDto[]
    | undefined
    | null;
  onChange: (
    event: React.SyntheticEvent | null,
    userAziendaStabilimentoRepartoDto: UserAziendaStabilimentoRepartoDto[]
  ) => void;
}) => {
  const [elements, setElements] = useState<StabilimentoReparti[]>([]); // for temporary changes

  useEffect(() => {
    const currentElements = groupStabilimenti(
      userAziendaStabilimentoRepartoDto ?? []
    );
    setElements(currentElements);
  }, [userAziendaStabilimentoRepartoDto]);

  const { data: aziende, isLoading: aziendeLoading } = useGetAziende();

  const currentAzienda = useMemo(() => {
    return aziende?.data?.find((a) => a.id === selectedAzienda?.id);
  }, [aziende, selectedAzienda]);

  const addElement = () => {
    const element: StabilimentoReparti = {
      stabilimento: null,
      reparti: [],
    };
    setElements([...elements, element]);
  };

  const deleteElement = (element: StabilimentoReparti) => {
    const newElements = elements.filter((e) => e !== element);
    onChange(null, ungroupStabilimenti(newElements));
  };

  return (
    <>
      {currentAzienda && elements.length > 0 && (
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={5}>
            <InputLabel>Stabilimento</InputLabel>
          </Grid>
          <Grid item xs={5}>
            <InputLabel>Reparto</InputLabel>
          </Grid>
          {elements.map((element, index) => {
            return (
              <Grid item xs={12} key={index}>
                <StabilimentiRepartiPicker
                  key={index}
                  selectedAzienda={currentAzienda}
                  stabilimentoReparti={element}
                  selectedStabilimentiReparti={elements}
                  onChange={(event, stabilimento, reparti) => {
                    const newElements = elements.map((e, i) => {
                      if (i === index) {
                        return {
                          stabilimento: stabilimento,
                          reparti: reparti,
                        };
                      }
                      return e;
                    });
                    onChange(event, ungroupStabilimenti(newElements)); // Confirm change to model
                  }}
                  onDelete={deleteElement}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {elements.length < (currentAzienda?.stabilimenti?.length ?? 0) && (
        <Button onClick={addElement}>Aggiungi stabilimento</Button>
      )}
    </>
  );
};

export default StabilimentiPicker;

function groupStabilimenti(
  userAziendaStabilimentoReparto: UserAziendaStabilimentoRepartoDto[]
): StabilimentoReparti[] {
  const stabilimentiReparti: StabilimentoReparti[] = [];
  userAziendaStabilimentoReparto.forEach((element) => {
    const stabilimentoReparti = stabilimentiReparti.find(
      (s) => s.stabilimento?.id === element.stabilimento?.id
    );
    if (stabilimentoReparti) {
      stabilimentoReparti.reparti?.push(element.reparto as RepartoDto);
    } else {
      stabilimentiReparti.push({
        stabilimento: element.stabilimento as StabilimentoDto,
        reparti: element.reparto ? [element.reparto as RepartoDto] : [],
      });
    }
  });
  return stabilimentiReparti;
}

function ungroupStabilimenti(
  stabilimentoReparti: StabilimentoReparti[]
): UserAziendaStabilimentoRepartoDto[] {
  const userAziendaStabilimentoReparto: UserAziendaStabilimentoRepartoDto[] =
    [];
  stabilimentoReparti
    .filter((element) => {
      return element.stabilimento;
    })
    .forEach((element) => {
      if (!element.reparti || element.reparti.length === 0) {
        userAziendaStabilimentoReparto.push({
          id: -1,
          stabilimentoId: `${element.stabilimento?.id ?? -1}`,
          stabilimento: element.stabilimento as StabilimentoDto,
          repartoId: undefined,
          reparto: undefined,
        });
      } else {
        element.reparti?.forEach((reparto) => {
          userAziendaStabilimentoReparto.push({
            id: -1,
            stabilimentoId: `${element.stabilimento?.id ?? -1}`,
            stabilimento: element.stabilimento as StabilimentoDto,
            repartoId: `${reparto.id}`,
            reparto: reparto,
          });
        });
      }
    });
  return userAziendaStabilimentoReparto;
}
