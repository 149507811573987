import {
  DispositivoDto,
  getDispositivoHistoryDataForCSV,
  GetDispositivoHistoryDataForCSVParams,
  ProprietaMisurataDto,
  ProprietaMisurataValueDto,
} from "@/api";
import Button from "@/elements/Button";
import { getProprietaMisurataHelper } from "@/utils/proprietaMisurataUtils";
import { withOpeningReset } from "@/utils/withOpeningReset";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DateField } from "@mui/x-date-pickers";
import { orderBy } from "lodash";
import moment from "moment";
import * as Papa from "papaparse";
import { useMemo, useState } from "react";
import { SmallLoading } from "../Loading";

function DispositivoCSVDialog({
  open = false,
  onClose,
  dispositivo,
}: {
  open?: boolean;
  onClose?: (dispositivo: DispositivoDto | null) => void;
  dispositivo?: DispositivoDto;
}) {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const [misura, setMisura] = useState<ProprietaMisurataDto | null>(null);
  const [period, setPeriod] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isValid = useMemo(() => {
    return !!from && !!to && !!misura && !!period;
  }, [from, to, misura, period]);

  const availableMisure = useMemo(() => {
    const proprieta = (dispositivo?.proprietaMisurate || []).filter(
      (p) => p.type !== "unknown"
    );

    return orderBy(
      proprieta,
      (m) => {
        const label = getProprietaMisurataHelper(m.type)?.label ?? m.type;
        return label;
      },
      "asc"
    );
  }, [dispositivo]);

  const availablePeriods = useMemo(() => {
    return ["10s", "1m", "2m", "4m", "10m", "30m", "1h"];
  }, []);

  const handleClose = () => {
    onClose?.(null);
  };

  const handleSubmit = async (onSubmit: any) => {
    try {
      setIsLoading(true);

      const dispositivoId = dispositivo?.id;
      if (!dispositivoId) {
        throw new Error("Dispositivo non trovato");
      }
      if (misura === null) {
        throw new Error("Misura non selezionata");
      }
      if (period === null) {
        throw new Error("Periodo non selezionato");
      }
      if (!from) {
        throw new Error("Data di inizio non selezionata");
      }
      const dateFromString = moment(from)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");

      if (!to) {
        throw new Error("Data di fine non selezionata");
      }
      const dateToString = moment(to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

      var params = {
        from: dateFromString,
        to: dateToString,
        period: "1h",
      } as GetDispositivoHistoryDataForCSVParams;
      const data = await getDispositivoHistoryDataForCSV(
        dispositivo?.id,
        misura.id,
        params
      );

      const title =
        dispositivo.matricola +
        "_" +
        getProprietaMisurataHelper(misura.type)?.label;
      downloadCSV(title, data.data);
      handleClose();
    } catch (error) {
      console.error(error);
      alert("Errore durante la generazione del file CSV");
    }
    setIsLoading(false);
  };

  const downloadCSV = (
    title: string,
    proprietaMisurate: ProprietaMisurataValueDto[]
  ) => {
    const csv = createCSV(proprietaMisurate);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", title);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const createCSV = (proprietaMisurate: ProprietaMisurataValueDto[]) => {
    const csvRows: { Data: string; Valore: number | null }[] = [];

    proprietaMisurate.forEach((proprietaMisurata) => {
      const row = {
        Data: moment(proprietaMisurata.timestamp).format("DD/MM/YYYY HH:mm"),
        Valore: proprietaMisurata.value ?? null,
        //proprietaMisurata.dangerMin,
        //proprietaMisurata.dangerMax,
      };
      csvRows.push(row);
    });

    return Papa.unparse(csvRows);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Scarica dati grezzi</DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="csv_misura">Misura</InputLabel>
        <Autocomplete<ProprietaMisurataDto>
          onChange={(e, value) => setMisura(value)}
          options={availableMisure}
          getOptionLabel={(misura) =>
            getProprietaMisurataHelper(misura.type)?.label ?? misura.type
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={misura}
          renderInput={(params) => <TextField {...params} value={misura} />}
          size="medium"
        />

        <InputLabel htmlFor="csv_period">Cardinalità</InputLabel>
        <Autocomplete<string>
          onChange={(e, value) => setPeriod(value)}
          options={availablePeriods}
          getOptionLabel={(period) => period}
          isOptionEqualToValue={(option, value) => option === value}
          value={period}
          renderInput={(params) => <TextField {...params} value={period} />}
          size="medium"
        />

        <InputLabel htmlFor="csv_da">Da</InputLabel>
        <DateField
          value={from}
          onChange={(date) => {
            setFrom(date);
          }}
        />

        <InputLabel htmlFor="csv_a">A</InputLabel>
        <DateField value={to} onChange={(date) => setTo(date)} />
      </DialogContent>

      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit}
        >
          Scarica
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(DispositivoCSVDialog);
