import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { Card, Grid, Icon } from "@mui/material";
import typography from "@/theme/base/typography";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  ArcElement,
} from "chart.js";
import { ProprietaMisurataDto, ProprietaMisurataType } from "@/api";
import ProprietaMisurataLabel from "../ProprietaMisurataLabel";
import { useMemo } from "react";
import { classeDiContaminazione } from "@/utils/proprietaMisurataUtils";

ChartJS.register(
  LineController,
  LineElement,
  ArcElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
);

function ProprietaISOMisurateCard({
  proprietaMisurate,
}: {
  proprietaMisurate: ProprietaMisurataDto[];
}) {
  const { size } = typography;
  const danger = true;
  const color = "error";

  const classeContaminazione = useMemo(() => {
    return classeDiContaminazione(proprietaMisurate)
  }, [proprietaMisurate]);

  return (
    <Card sx={{ width: 332 }}>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        flex={1}
        height="100%"
      >
        <Typography
          variant="body2"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {"Particelle ISO"}
        </Typography>

        <Box display="flex" alignItems="center" mb={1} flexDirection="column" justifyContent="center" height="100%">
          <Grid
            container
            rowSpacing={1} // Adjust spacing between grid items
            columnSpacing={2} // Adjust spacing between grid items
            sx={{
              justifyContent: "center",
              alignItems: "flex-start"
            }}
          >
            {proprietaMisurate.map((proprietaMisurata, index) => (
              <Grid item xs={6} key={index}>
                {/* Adjusted to fit max 2 elements per row */}
                <ProprietaMisurataLabel
                  proprietaMisurata={proprietaMisurata}
                  value={proprietaMisurata.lastValue || null}
                  alignItems="center"
                />
              </Grid>
            ))}
          </Grid>

          { classeContaminazione && <Typography
          variant="body2"
          fontWeight="bold"
          fontSize={size.md}
          textAlign={"center"}
          paddingTop={3}
          color="text">Classe di contaminazione<br></br>{classeContaminazione}</Typography>}
        </Box>
      </Box>
    </Card>
  );
}

export default ProprietaISOMisurateCard;
