import {
  AziendaDto,
  CentraleDto,
  RepartoDto,
  StabilimentoDto,
  TrasferimentoDtoModalita,
} from "@/api";
import { useState } from "react";

export type PosizioneFilter = {
  azienda: AziendaDto | null;
  stabilimento: StabilimentoDto | null;
  reparto: RepartoDto | null;
  centrale: CentraleDto | null;
  titolare: AziendaDto | null;
  modalita: TrasferimentoDtoModalita | null;
};

export function usePosizioneFilter() {
  const [filters, setFilters] = useState<PosizioneFilter>({
    azienda: null,
    stabilimento: null,
    reparto: null,
    centrale: null,
    titolare: null,
    modalita: null,
  });

  const changeFilter =
    (key: keyof typeof filters, resetKeys?: (keyof typeof filters)[]) =>
    (event: any, value: (typeof filters)[typeof key] | undefined) => {
      setFilters((f) => {
        const newFilters = {
          ...f,
          [key]: value || null,
        };
        resetKeys?.forEach((k) => {
          newFilters[k] = null;
        });
        return newFilters;
      });
    };

  return [filters, changeFilter, setFilters] as const;
}
