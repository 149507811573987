import {
  DispositivoDto,
  RegistroDto,
  useGetRegistriDispositivo,
  useUpdateRegistriDispositivo,
} from "@/api";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DispositivoMapper } from "./dispositivi";
import { isNil, cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { SmallLoading } from "../Loading";
import { ModelloDispositivo } from "@/utils/modelliDispositivi";

function RegistriDispositivoDialog({
  open = false,
  onClose,
  dispositivo,
}: {
  open?: boolean;
  onClose?: () => void;
  dispositivo?: DispositivoDto;
}) {
  const { hasPermission } = useAuth();
  const mapper = dispositivo?.modello
    ? DispositivoMapper[dispositivo.modello as ModelloDispositivo]
    : undefined;

  const { data } = useGetRegistriDispositivo(dispositivo?.id ?? 0, {
    query: { enabled: Boolean(open && dispositivo?.id && mapper) },
  });

  const {
    control,
    reset,
    resetField,
    handleSubmit,
    formState: { errors },
    register,
    setError,
    clearErrors,
    getValues,
  } = useForm<any>({});
  const [initialData, setInitialData] = useState<any>({});

  const { mutateAsync, isLoading } = useUpdateRegistriDispositivo();

  useEffect(() => {
    if (!data) {
      return;
    }

    if (mapper) {
      const formData = mapper.registriToForm(data.data);
      reset(formData);
      setInitialData(cloneDeep(formData));
    }
  }, [data, reset, mapper]);

  const handleClose = () => {
    onClose?.();
  };

  async function onSubmit(form: any) {
    if (!dispositivo || !mapper || !data?.data) {
      return;
    }

    clearErrors();

    const newValues = mapper.formToRegistri(form);
    const differences = [];

    for (const newValue of newValues) {
      if (
        !isNil(newValue) &&
        initialData[newValue.registro] !== form[newValue.registro]
      ) {
        differences.push(newValue);
      }
    }

    if (differences.length > 0) {
      try {
        console.log("differences", differences);

        const response = await mutateAsync({
          id: dispositivo.id,
          data: differences,
          mode: mapper.mode,
        });

        if (!response.data) {
          toast.error(
            "Si è verificato un errore durante la modifica dei registri"
          );
          return;
        }

        let nonAggiornati = 0;
        let aggiornatiDiversi = 0;
        let aggiornatiCorrettamente = 0;

        for (const update of response.data) {
          const registro = update.registro;

          if (
            update.changed &&
            update.newValue !== null &&
            update.newValue !== undefined &&
            update.newValueTime !== null &&
            update.newValueTime !== undefined
          ) {
            const registroAggiornato = {
              registro: registro,
              value: update.newValue,
              time: update.newValueTime,
            };
            const mapped = mapper.registriToForm([registroAggiornato]);

            // mostro il valore più aggiornato fornito dal dispositivo
            resetField(registro, {
              defaultValue: mapped[registro],
            });

            if (mapped[registro] === form[registro]) {
              aggiornatiCorrettamente++;
            } else {
              aggiornatiDiversi++;
              setError(registro, {
                message:
                  "Il dispositivo ha salvato un valore diverso da quello inserito.",
              });
            }
          } else {
            nonAggiornati++;
            setError(registro, {
              message: "Conferma dal dispositivo non ricevuta",
            });
          }
        }

        console.log("nonAggiornati", nonAggiornati);
        console.log("aggiornatiDiversi", aggiornatiDiversi);
        console.log("aggiornatiCorrettamente", aggiornatiCorrettamente);

        if (differences.length === aggiornatiCorrettamente) {
          toast.success("Registri salvati con successo!");
          handleClose();
        } else if (differences.length === nonAggiornati) {
          toast.error(
            "I cambiamenti sono stati inviati al dispositivo, ma non è stata ricevuta alcuna risposta"
          );
        } else {
          toast.warn(
            "Non tutti i registri sono stati aggiornati correttamente. Controlla i valori indicati in rosso"
          );
        }
      } catch (e) {
        console.error(e);
        toast.error("Errore durante il salvataggio dei registri");
      }
    } else {
      handleClose();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Registri dispositivo</DialogTitle>
      <DialogContent>
        {mapper?.Component ? (
          <mapper.Component
            control={control}
            errors={errors}
            register={register}
            readOnly={isLoading || !hasPermission("DispositivoRegistriWrite")}
            getValues={getValues}
          />
        ) : (
          <Box justifyContent="center" alignItems="center" padding={2}>
            <Typography>
              Modello del dispositivo "{dispositivo?.modello}" sconosciuto.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        {hasPermission("DispositivoRegistriWrite") && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Salva
          </Button>
        )}
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(RegistriDispositivoDialog);
