import { RegistriMode, RegistroDto, UpdateRegistroDto } from "@/api";
import {
  EditorRegistriGFLTFL700,
  formGFLTFL700ToRegistri,
  registriGFLTFL700ToForm,
} from "./GFLTFL700";
import { ModelloDispositivo } from "@/utils/modelliDispositivi";
import {
  EditorRegistri_GFL_MONITORING_WTP,
  formToRegistri_GFL_MONITORING_WTP,
  registriToForm_GFL_MONITORING_WTP,
} from "./GFL_MONITORING_WTP";
import {
  EditorRegistri_GFL_MONITORING_WT,
  formToRegistri_GFL_MONITORING_WT,
  registriToForm_GFL_MONITORING_WT,
} from "./GFL_MONITORING_WT";
import {
  EditorRegistri_GFL_SENSOR_WT,
  formToRegistri_GFL_SENSOR_WT,
  registriToForm_GFL_SENSOR_WT,
} from "./GFL_SENSOR_WT";

export const DispositivoMapper: {
  [key in ModelloDispositivo]?: Mapper;
} = {
  "GFL TFL 700": {
    Component: EditorRegistriGFLTFL700,
    registriToForm: registriGFLTFL700ToForm,
    formToRegistri: formGFLTFL700ToRegistri,
    mode: "ManyTopics",
  },
  "GFL TFLG 700": {
    Component: EditorRegistriGFLTFL700,
    registriToForm: registriGFLTFL700ToForm,
    formToRegistri: formGFLTFL700ToRegistri,
    mode: "ManyTopics",
  },
  "GFL MONITORING WTP": {
    Component: EditorRegistri_GFL_MONITORING_WTP,
    registriToForm: registriToForm_GFL_MONITORING_WTP,
    formToRegistri: formToRegistri_GFL_MONITORING_WTP,
    mode: "SingleJsonMessage",
  },
  "GFL MONITORING WT": {
    Component: EditorRegistri_GFL_MONITORING_WT,
    registriToForm: registriToForm_GFL_MONITORING_WT,
    formToRegistri: formToRegistri_GFL_MONITORING_WT,
    mode: "SingleJsonMessage",
  },
  "GFL SENSOR WT": {
    Component: EditorRegistri_GFL_SENSOR_WT,
    registriToForm: registriToForm_GFL_SENSOR_WT,
    formToRegistri: formToRegistri_GFL_SENSOR_WT,
    mode: "SingleJsonMessage",
  },
};

export type Mapper<Data = Record<string, any>> = {
  Component: any;
  registriToForm: (registri: RegistroDto[]) => Data;
  formToRegistri: (registri: Data) => UpdateRegistroDto[];
  mode: RegistriMode;
};
