import Button from "@/elements/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  UserEsternoCreateDto,
  UserEsternoDto,
  UserEsternoUpdateDto,
  getGetClientiQueryKey,
  getGetUsersQueryKey,
  useCreateCliente,
  useUpdateCliente,
} from "@/api";
import { Controller, UseFormSetError, useForm } from "react-hook-form";
import { withOpeningReset } from "../../utils/withOpeningReset";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import { AxiosError } from "axios";
import AziendaPicker from "../AziendaPicker";
import StabilimentoRepartiPicker from "../StabilimentoRepartiPicker";
import StabilimentiPicker from "../StabilimentiPicker";

function ClienteDialog({
  open = false,
  onClose,
  cliente,
}: {
  open?: boolean;
  onClose?: () => void;
  cliente?: UserEsternoDto | null;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm<UserEsternoDto>({
    defaultValues: cliente || {},
  });

  const { saveAsync, isLoading, error } = useMutator(cliente, setError);

  const queryClient = useQueryClient();

  const azienda = watch("azienda");
  
  const onSubmit = async (data: UserEsternoDto) => {
    console.log("data:", data);
    try {
      await saveAsync(data);
      queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
      queryClient.invalidateQueries({ queryKey: getGetClientiQueryKey() });
      onClose?.();
    } catch (err) {}
  };
  
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {cliente ? "Modifica account" : "Creazione account"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="user-nome">Nome</InputLabel>
        <TextField
          id="user-nome"
          autoFocus
          {...register("name", {
            required: "Campo obbligatorio",
            minLength: 1,
          })}
          error={!!errors.name?.message}
          helperText={errors.name?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="user-cognome">Cognome</InputLabel>
        <TextField
          id="user-cognome"
          autoFocus
          {...register("lastName", {
            required: "Campo obbligatorio",
            minLength: 1,
          })}
          error={!!errors.lastName?.message}
          helperText={errors.lastName?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="user-email">Email</InputLabel>
        <TextField
          id="user-email"
          autoFocus
          {...register("email", {
            required: "Campo obbligatorio",
            minLength: 1,
          })}
          error={!!errors.email?.message}
          helperText={errors.email?.message || null}
          fullWidth
        />

        
        
        <InputLabel>Azienda</InputLabel>
        <Controller
          name={"azienda"}
          control={control}
          render={({ field }) => {
            return (
              <AziendaPicker
                {...field}
                onChange={(e, v) => {
                  field.onChange(v);
                  if (v?.id != cliente?.azienda?.id) {
                    setValue("userAziendaStabilimentoReparto", []);
                  }
                }}
              />
            );
          }}
        />

        <Controller
          name={"userAziendaStabilimentoReparto"}
          control={control}
          render={({ field }) => {
            return (
              <StabilimentiPicker
                selectedAzienda={azienda}
                userAziendaStabilimentoRepartoDto={field.value}
                onChange={(e, v) => {
                  field.onChange(v);
                }}
              />
            );
          }}
        />

        {errors.azienda && (
          <FormHelperText error>{errors.azienda?.message}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {cliente ? "Salva account " : "Crea account"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(ClienteDialog);

function useMutator(
  cliente: UserEsternoDto | null | undefined,
  setError: UseFormSetError<UserEsternoDto>
): {
  isLoading: boolean;

  error: AxiosError<unknown, any> | null;
  saveAsync: (data: UserEsternoDto) => Promise<any>;
} {
  const isNew = !cliente?.id;

  const create = useCreateCliente({
    mutation: {
      onSuccess: () => {
        toast.success("Account creato!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const update = useUpdateCliente({
    mutation: {
      onSuccess: () => {
        toast.success("Account salvato!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const { isLoading, error } = isNew ? create : update;

  return {
    isLoading,
    error,
    saveAsync: async (data: UserEsternoDto) => {
      return isNew
        ? create.mutateAsync({ data: data as UserEsternoCreateDto })
        : update.mutateAsync({
            id: cliente.id!,
            data: data as UserEsternoUpdateDto,
          });
    },
  };
}
