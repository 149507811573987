/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */

export type TrasferimentoModalita =
  (typeof TrasferimentoModalita)[keyof typeof TrasferimentoModalita];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TrasferimentoModalita = {
  service: "service",
  noleggio: "noleggio",
  venduto: "venduto",
} as const;
