import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { Icon } from "@mui/material";
import { ComponentProps } from "react";

function DispositivoStatus({
  type,
  status,
  controls = null,
  manuale = false,
  alarm = false,
}: {
  type: "power" | "connection";
  status: boolean;
  controls?: JSX.Element | null;
  manuale?: boolean | null;
  alarm?: boolean | null;
}) {
  let icon: JSX.Element | null = null;
  let label: string | null = null;
  let statusColor: ComponentProps<typeof Typography>["color"];

  if (type === "power") {
    icon = <Icon>power_settings_news</Icon>;
    if (status) {
      label = "ON";
    } else {
      label = "OFF";
    }
    if (manuale && label) {
      label += " (manuale)";
    }
  } else if (type === "connection") {
    if (status) {
      icon = <Icon>cloud_queue</Icon>;
      label = "ONLINE";
    } else {
      icon = <Icon>cloud_off</Icon>;
      label = "OFFLINE";
    }
  }

  if (alarm) {
    statusColor = "warning";
  } else if (status) {
    statusColor = "success";
  } else {
    statusColor = "error";
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-start">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        minWidth={130}
      >
        <Typography
          variant="h3"
          color={statusColor}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {icon}
        </Typography>

        <Typography variant="h6" ml={1} mr={2}>
          {label}
        </Typography>
      </Box>

      {controls}
    </Box>
  );
}

export default DispositivoStatus;
