import {
  ProprietaMisurataDto,
  ProprietaMisurataType as ImportedProprietaMisurataType,
  ProprietaMisurataValueDto,
  ProprietaMisurataType,
} from "@/api";
import moment from "moment";
import numeral from "numeral";
import { _proprietaMisurataUtilsData } from "./proprietaMisurataUtilsData";

export { ProprietaMisurataType } from "@/api";

export interface ProprietaMisurataTypeHelper {
  label: string;
  shortLabel?: string;
  unit: string | null;
  unitPosition: "left" | "right" | false;
  format: (value: number) => string;
  getPercent: (value: number) => number;
}

const proprietaMisurataTypes: {
  [type in Exclude<
    ProprietaMisurataType,
    "unknown"
  >]: ProprietaMisurataTypeHelper;
} = {
  temperatura: generateHelper(_proprietaMisurataUtilsData.Temperatura),
  particelle: generateHelper(_proprietaMisurataUtilsData.Particelle), // deprecato
  particelle_iso4: generateHelper(_proprietaMisurataUtilsData.Particelle_iso4),
  particelle_iso6: generateHelper(_proprietaMisurataUtilsData.Particelle_iso6),
  particelle_iso14: generateHelper(
    _proprietaMisurataUtilsData.Particelle_iso14
  ),
  particelle_iso21: generateHelper(
    _proprietaMisurataUtilsData.Particelle_iso21
  ),
  pressione: generateHelper(_proprietaMisurataUtilsData.Pressione),
  umidita: generateHelper(_proprietaMisurataUtilsData.Umidita),
  potenzaMotore: generateHelper(_proprietaMisurataUtilsData.PotenzaMotore),
  portata: generateHelper(_proprietaMisurataUtilsData.Portata),
  saturazione: generateHelper(_proprietaMisurataUtilsData.Saturazione),
} as const;

export function getProprietaMisurataHelper(
  type: ProprietaMisurataType
): ProprietaMisurataTypeHelper | null {
  if (type === "unknown") {
    return null;
  }
  const h = proprietaMisurataTypes[type];
  if (!h) {
    console.error("[ProprietaMisurata] Unknown type: " + type);
  }
  return h || null;
}

export type ProprietaMisurataISOType =
  | typeof ProprietaMisurataType.particelle_iso4
  | typeof ProprietaMisurataType.particelle_iso6
  | typeof ProprietaMisurataType.particelle_iso14
  | typeof ProprietaMisurataType.particelle_iso21;

export function isIso(
  type: ProprietaMisurataType
): type is ProprietaMisurataISOType {
  return (
    type === ProprietaMisurataType.particelle_iso4 ||
    type === ProprietaMisurataType.particelle_iso6 ||
    type === ProprietaMisurataType.particelle_iso14 ||
    type === ProprietaMisurataType.particelle_iso21
  );
}

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

function generateHelper(
  d: (typeof _proprietaMisurataUtilsData)[keyof typeof _proprietaMisurataUtilsData]
): ProprietaMisurataTypeHelper {
  const unit = d.unit;
  const unitPosition = (d.unitPosition ||
    false) as ProprietaMisurataTypeHelper["unitPosition"];
  return {
    label: d.label,
    shortLabel: d.shortLabel,
    unit: d.unit,
    unitPosition: unitPosition,
    format: (value) => {
      const valueString = numeral(value).format("0.0");
      if (d.unit) {
        return unitPosition === "left"
          ? `${unit} ${valueString}`
          : `${valueString} ${unit}`;
      }
      return valueString;
    },
    getPercent: makeGetPercent(d.percentMin, d.percentMax),
  };
}

function makeGetPercent(min: number, max: number) {
  const rangeDelta = max - min;
  const MIN = 5;
  const MAX = 100;
  return (value: number) => {
    const delta = value - min;
    let perc = (delta * 100) / rangeDelta;
    if (perc < MIN) {
      perc = MIN;
    } else if (perc > MAX) {
      perc = MAX;
    }
    return perc;
  };
}

export function isDanger(value: ProprietaMisurataValueDto | null) {
  return typeof value?.value === "number"
    ? (typeof value.dangerMin === "number" && value.value <= value.dangerMin) ||
    (typeof value.dangerMax === "number" && value.value >= value.dangerMax)
    : false;
}

export function isOld(value: ProprietaMisurataValueDto | null) {
  if (!value) { return true; }
  const maxDiff = 2 * 24 * 60 * 60 * 1000;
  const diff = moment().diff(moment(value.timestamp));
  return diff >= maxDiff;
}

export function splitProprietaStandardISO(proprietaMisurate: ProprietaMisurataDto[], minimumISONumber: number = 2) {
  const proprietaStandardMisurate = proprietaMisurate.filter((p) => !isIso(p.type));
  const proprietaISOMisurate = proprietaMisurate.filter((p) => isIso(p.type));
  return {
    proprietaStandardMisurate: proprietaISOMisurate.length >= minimumISONumber ? proprietaStandardMisurate : proprietaMisurate,
    proprietaISOMisurate: proprietaISOMisurate.length >= minimumISONumber ? proprietaISOMisurate : []
  };
}

export function classeDiContaminazione(proprietaMisurate: ProprietaMisurataDto[]): string | null {
  // https://www.ufihyd.com/it/blog/capire-i-materiali-filtranti-e-le-classi-di-contaminazione-nei-sistemi-oleodinamici/#:~:text=La%20classe%20di%20contaminazione%20di,4µm%2C%206µm%2C%2014µm).
  const iso4 = proprietaMisurate.find(((p) => p.type === ImportedProprietaMisurataType.particelle_iso4))?.lastValue?.value;
  const iso6 = proprietaMisurate.find(((p) => p.type === ImportedProprietaMisurataType.particelle_iso6))?.lastValue?.value;
  const iso14 = proprietaMisurate.find(((p) => p.type === ImportedProprietaMisurataType.particelle_iso14))?.lastValue?.value

  if (iso4 && iso6 && iso14) {
    return [iso4, iso6, iso14].join("/");
  }
  return null;
}

