import Box from "@/elements/Box";
import Button from "@/elements/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Icon,
  Autocomplete,
  Select,
  TextField,
} from "@mui/material";
import {
  DispositivoDto,
  TrasferimentoDto,
  TrasferimentoDtoModalita,
  TrasferimentoModalita,
  getGetDispositiviQueryKey,
  getGetDispositivoQueryKey,
  useUpdateTrasferimentiDispositivo,
} from "@/api";
import { getDispositivoTypeLabel } from "@/utils/dispositivoUtils";
import { ColumnDef, Row, createColumnHelper } from "@tanstack/react-table";
import Table from "@/elements/Table";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTimeField } from "@mui/x-date-pickers";
import AziendaPicker from "@/components/AziendaPicker";
import {
  IS_NEW_KEY,
  useDbRelationsUpdate,
  useDbRelationsUpdateForm,
} from "@/utils/useDbRelationsUpdate";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { withOpeningReset } from "../../utils/withOpeningReset";
import { useAuth } from "@/context/useAuth";
import StabilimentoPicker from "../StabilimentoPicker";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import RepartoPicker from "../RepartoPicker";
import CentralePicker from "../CentralePicker";
import { element } from "prop-types";

const columnHelper = createColumnHelper<TrasferimentoDto>();

function TrasferimentiDialog({
  open = false,
  canEdit = false,
  onClose,
  dispositivo,
  trasferimenti,
}: {
  open?: boolean;
  canEdit?: boolean;
  onClose?: () => void;
  dispositivo?: DispositivoDto;
  trasferimenti?: TrasferimentoDto[];
}) {
  const dispositivoLabel = dispositivo
    ? `${getDispositivoTypeLabel(dispositivo.type)} ${dispositivo.matricola}`
    : "";

  const handleClose = () => {
    onClose?.();
  };

  const editing = canEdit;

  const [modalita, setModalita] = useState<TrasferimentoDtoModalita | null>(
    null
  );

  const {
    data,
    control,
    resetField,
    watch,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
  } = useDbRelationsUpdateForm(trasferimenti);

  const { hasPermission } = useAuth();

  const columns = useMemo<ColumnDef<TrasferimentoDto, any>[]>(() => {
    const editingDataTimeCell = (
      _: string | null | undefined,
      name: ReturnType<typeof getFieldName>
    ) => {
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <DateTimeField
                ref={ref}
                format="DD/MM/YYYY HH:mm"
                name={name}
                onBlur={onBlur}
                onChange={(value) => onChange(value?.toISOString())}
                value={m}
              />
            );
          }}
        />
      );
    };
    const readonlyDataTimeCell = (value: string | null | undefined) => {
      const m = value ? moment(value) : null;
      return m ? m.format("L LT") : "";
    };

    const dataTimeCell = editing ? editingDataTimeCell : readonlyDataTimeCell;

    const columns = [
      columnHelper.accessor("titolare.nome", {
        header: "Titolare *",
        size: 175,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "titolare")}
                control={control}
                render={({ field }) => {
                  return (
                    <AziendaPicker
                      {...field}
                      onChange={(e, v) => {
                        field.onChange(v);
                      }}
                      canCreateAzienda={false}
                    />
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("da", {
        header: "Da",
        size: 195,
        maxSize: 195,
        cell: ({ getValue, row }) =>
          dataTimeCell(getValue(), getFieldName(row.index, "da")),
      }),
      columnHelper.accessor("a", {
        header: "A",
        size: 195,
        maxSize: 195,
        cell: ({ getValue, row }) =>
          dataTimeCell(getValue(), getFieldName(row.index, "a")),
      }),
      columnHelper.accessor("azienda.nome", {
        header: "Azienda",
        size: 195,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "azienda")}
                control={control}
                render={({ field }) => {
                  return (
                    <AziendaPicker
                      {...field}
                      onChange={(e, v) => {
                        field.onChange(v);
                        resetField(getFieldName(row.index, "stabilimento"), {
                          defaultValue: null,
                          keepDirty: true,
                        });
                        resetField(getFieldName(row.index, "reparto"), {
                          defaultValue: null,
                          keepDirty: true,
                        });
                        resetField(getFieldName(row.index, "centrale"), {
                          defaultValue: null,
                          keepDirty: true,
                        });
                      }}
                      canCreateAzienda={
                        false /*hasPermission("AziendaCreate")*/
                      }
                    />
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("stabilimento.nome", {
        header: "Stabilimento",
        size: 195,
        cell: editing
          ? ({ row }) => {
              const azienda = watch(getFieldName(row.index, "azienda"));
              return azienda ? (
                <Controller
                  name={getFieldName(row.index, "stabilimento")}
                  control={control}
                  render={({ field }) => {
                    return (
                      <StabilimentoPicker
                        {...field}
                        onChange={(e, v) => {
                          field.onChange(v);
                          resetField(getFieldName(row.index, "reparto"), {
                            defaultValue: null,
                            keepDirty: true,
                          });
                          resetField(getFieldName(row.index, "centrale"), {
                            defaultValue: null,
                            keepDirty: true,
                          });
                        }}
                        aziendaId={azienda.id}
                        canCreateStabilimento={
                          false /*hasPermission(
                          "StabilimentoCreate"
                        )*/
                        }
                      />
                    );
                  }}
                />
              ) : null;
            }
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("reparto.nome", {
        header: "Reparto",
        size: 195,
        cell: editing
          ? ({ row }) => {
              const stabilimento = watch(
                getFieldName(row.index, "stabilimento")
              );
              return stabilimento ? (
                <Controller
                  name={getFieldName(row.index, "reparto")}
                  control={control}
                  render={({ field }) => {
                    return (
                      <RepartoPicker
                        {...field}
                        onChange={(e, v) => {
                          field.onChange(v);
                          resetField(getFieldName(row.index, "centrale"), {
                            defaultValue: null,
                            keepDirty: true,
                          });
                        }}
                        stabilimentoId={stabilimento.id}
                        canCreateReparto={
                          false /*hasPermission("RepartoCreate")*/
                        }
                      />
                    );
                  }}
                />
              ) : null;
            }
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("centrale.nome", {
        header: "Centrale",
        size: 195,
        cell: editing
          ? ({ row }) => {
              const reparto = watch(getFieldName(row.index, "reparto"));
              return reparto ? (
                <Controller
                  name={getFieldName(row.index, "centrale")}
                  control={control}
                  render={({ field }) => {
                    return (
                      <CentralePicker
                        {...field}
                        onChange={(e, v) => {
                          field.onChange(v);
                        }}
                        repartoId={reparto.id}
                        canCreateCentrale={
                          false /*hasPermission("CentraleCreate")*/
                        }
                      />
                    );
                  }}
                />
              ) : null;
            }
          : ({ getValue }) => getValue() || null,
      }),
      columnHelper.accessor("modalita", {
        header: "Modalità",
        size: 175,
        cell: editing
          ? ({ row }) => (
              <Controller
                name={getFieldName(row.index, "modalita")}
                control={control}
                render={({ field }) => {
                  return (
                    <Autocomplete<TrasferimentoDtoModalita>
                      onChange={(e, value) => {
                        field.onChange(value);
                      }}
                      options={Object.values(TrasferimentoModalita)}
                      getOptionLabel={(trasferimento) => {
                        return `${trasferimento}`;
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      value={field.value}
                      renderInput={(params) => (
                        <TextField {...params} value={field.value} />
                      )}
                      size="medium"
                    />
                  );
                }}
              />
            )
          : ({ getValue }) => getValue() || null,
      }),
      editing &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: 125,
          maxSize: 125,
          cell: ({ row }) => {
            return (
              <>
                <Box display="flex" gap={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<Icon>delete_outlined</Icon>}
                    onClick={() => {
                      remove(row.index);
                    }}
                  >
                    Elimina
                  </Button>
                </Box>
                <Box
                  display="flex"
                  gap={1}
                  className="deleted-row-visible-content"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<Icon>restore_from_trash</Icon>}
                    onClick={() => {
                      restore(row.index);
                    }}
                  >
                    Ripristina
                  </Button>
                </Box>
              </>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [
    editing,
    control,
    getFieldName,
    hasPermission,
    resetField,
    watch,
    remove,
    restore,
  ]);

  const isRowDeleted = useCallback(
    (row: Row<TrasferimentoDto>) => {
      return getEntityState(row.index) === "deleted";
    },
    [getEntityState]
  );

  const { mutateAsync, isLoading, error } = useUpdateTrasferimentiDispositivo({
    mutation: {
      onSuccess: () => {
        toast.success("Trasferimenti salvati!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const data = getChangeSubmitData();
    console.log("change data:", data);
    try {
      await mutateAsync({
        id: dispositivo!.id as number,
        data: data,
      });
      queryClient.invalidateQueries({ queryKey: getGetDispositiviQueryKey() });
      queryClient.invalidateQueries({
        queryKey: getGetDispositivoQueryKey(dispositivo!.id),
      });
      onClose?.();
    } catch (err) {}
  };

  const isValid = useMemo(() => {
    const { data } = watch();
    return data.every((x: TrasferimentoDto) => {
      return x.titolare;
    });
  }, [watch()]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
      <DialogTitle>Trasferimenti - {dispositivoLabel}</DialogTitle>
      <DialogContent>
        {trasferimenti && (
          <Box>
            <Box>
              {editing && (
                <Button
                  size="small"
                  color="light"
                  onClick={() => {
                    create();
                  }}
                >
                  Aggiungi trasferimento
                </Button>
              )}
            </Box>
            <Table
              columns={columns}
              data={data}
              sortable={false}
              initialSort={[
                { id: IS_NEW_KEY, desc: false },
                { id: "da", desc: true },
              ]}
              isRowDeleted={isRowDeleted}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        {editing && (
          <Button
            size="small"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Salva
          </Button>
        )}
        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(TrasferimentiDialog);
