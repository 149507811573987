import { FocusEventHandler, forwardRef, useEffect, useState } from "react";
import { AziendaDto, useGetAziende } from "@/api";
import AziendaDialog from "@/components/AziendaDialog";
import { AsyncAutocomplete } from "../../elements/Autocomplete";

const AziendaPicker = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      canCreateAzienda = false,
      placeholder,
      size,
    }: {
      value?: AziendaDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: AziendaDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateAzienda?: boolean;
      placeholder?: string;
      size?: "small" | "medium";
    },
    ref
  ) => {
    const [createAziendaDialogOptions, setCreateAziendaDialogOptions] =
      useState<{ open: boolean; nome?: string }>({ open: false, nome: "" });

    const onCreateAziendaClose = (azienda: AziendaDto | null) => {
      onChange?.(null, azienda);
      setCreateAziendaDialogOptions({ open: false });
    };

    function onNewOptionSelected(nome: string) {
      setCreateAziendaDialogOptions({ open: true, nome: nome });
    }
    
    return (
      <>
        <AsyncAutocomplete<AziendaDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={useGetAziende}
          getOptionLabel={(azienda) => azienda.nome}
          canCreateNewOption={canCreateAzienda}
          value={value}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          size={size}
        />
        {canCreateAzienda && (
          <AziendaDialog
            open={createAziendaDialogOptions.open}
            onClose={onCreateAziendaClose}
            azienda={{ nome: createAziendaDialogOptions.nome }}
          />
        )}
      </>
    );
  }
);

export default AziendaPicker;
