import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";

const pngSize = 512;
const widthRation = 0.85 * 0.35;
const heightRation = 0.85 * 0.48;
const level = "H";

export function GattiQrCode({
  value,
  size,
  fileName,
}: {
  value: string;
  size: number;
  fileName: string;
}) {
  const divRef = useRef<HTMLCanvasElement | undefined>();
  const svgRef = useRef<Node | undefined>();

  function copyToClipboard() {
    const canvas = divRef?.current?.querySelector("canvas") as
      | HTMLCanvasElement
      | undefined;
    if (!canvas) {
      return;
    }

    canvas.toBlob(function (blob) {
      if (!blob) {
        return;
      }

      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard
        .write([item])
        .then(function () {
          toast.success("Immagine QR code copiata negli appunti");
        })
        .catch(function (error) {
          console.error("Failed to copy image to clipboard:", error);
        });
    });
  }

  function downloadAsPng() {
    const canvas = divRef?.current?.querySelector("canvas") as
      | HTMLCanvasElement
      | undefined;
    if (!canvas) {
      return;
    }

    canvas.toBlob(function (blob) {
      if (!blob) {
        return;
      }

      const anchor = document.createElement("a");
      anchor.href = canvas.toDataURL();
      anchor.download = `${fileName}.png`;
      anchor.click();
    });
  }

  function downloadAsSvg() {
    const svg = svgRef.current;

    if (!svg) {
      return;
    }

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);

    // Add XML declaration (optional but recommended)
    const svgBlobContent = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n${svgString}`;

    // Create a Blob from the SVG string
    const blob = new Blob([svgBlobContent], { type: "image/svg+xml" });

    // Create an anchor element for downloading
    const a = document.createElement("a");
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = `${fileName}.svg`;

    // Append the anchor to the body (necessary for some browsers)
    document.body.appendChild(a);

    // Trigger a click event to start the download
    a.click();

    // Clean up: Remove the anchor and revoke the blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <QRCodeSVG
          value={value}
          size={size}
          level={level}
          ref={svgRef as any}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 1,
            padding: 0.5,
          }}
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Tooltip title="Scarica immagine PNG">
            <Box>
              <Button
                size="small"
                sx={{ color: "gray" }}
                onClick={downloadAsPng}
                startIcon={<DownloadIcon />}
              >
                PNG
              </Button>
            </Box>
          </Tooltip>

          <Tooltip title="Scarica immagine SVG">
            <Button
              size="small"
              sx={{ color: "gray" }}
              onClick={downloadAsSvg}
              startIcon={<DownloadIcon />}
            >
              SVG
            </Button>
          </Tooltip>

          <Tooltip title="Copia immagine">
            <IconButton size="small" color="primary" onClick={copyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <div style={{ display: "none" }} ref={divRef as any}>
        <QRCodeCanvas value={value} size={pngSize} level={level} />
      </div>
    </>
  );
}
